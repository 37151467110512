<template>
    <div class="bg-light rounded-2 p-2 mt-3">
        <div class="d-flex">
            <div class="w-50">
                <p class="text-soft-secondary text-uppercase fw-bold fs-6 mb-0">
                    {{ "box.data.name".trans() }}
                </p>
                <p class="text-dark fw-bold fs-5 mb-0">{{ data.originName }}</p>
            </div>
            <div class="w-50">
                <p class="text-soft-secondary text-uppercase fw-bold fs-6 mb-0">
                    {{ "box.data.mask".trans() }}
                </p>

                <p v-if="data.originMasterMask && (data.originMasterMask.maskTypeString != data.masterMask.maskTypeString || data.originTopic != data.Topic || data.departmentMask.label != data.originDepartment.label)" class="text-dark fw-bold fs-5 mb-0 text-decoration-line-through">
                    {{ data.masterMask.label }}
                    <span class="fw-normal">
                        - {{ data.originMasterMask.maskTypeString ? data.originMasterMask.maskTypeString : '/' }} -
                        {{ data.originTopic ? data.originTopic : '/' }} -
                        {{ data.originDepartment.label ? data.originDepartment.label : '/' }}
                    </span>
                </p>

                <p class="text-dark fw-bold fs-5 mb-0">
                    {{ data.masterMask.label }}
                    <span class="fw-normal">
                        - {{ data.masterMask.maskTypeString ? data.masterMask.maskTypeString : '/' }} -
                        {{ data.Topic ? data.Topic : '/' }} - {{ data.departmentMask.label ? data.departmentMask.label : '/' }}
                    </span>
                </p>
            </div>
            <div class="w-25">
                <p class="text-soft-secondary text-uppercase fw-bold fs-6 mb-0">
                    {{ "box.data.availability".trans() }}
                </p>
                <div class="bg-soft-secondary px-1 w-fit rounded">
                    <p class="text-dark fw-bold fs-5 mb-0">
                        {{ data.Availability }}
                    </p>
                </div>
            </div>
        </div>
        <div
            class="d-flex mt-2"
            v-if="draftData && draftData.Type == 'loyalty'"
        >
            <div class="w-50">
                <p class="text-soft-secondary text-uppercase fw-bold fs-6 mb-0">
                    {{ "loyalty.coupon.type".trans() }}
                </p>
                <p class="text-dark fw-bold fs-5 mb-0">
                    {{ ("loyalty.coupon.type." + draftData.SubType).trans() }}
                </p>
            </div>
            <div class="w-50">
                <p class="text-soft-secondary text-uppercase fw-bold fs-6 mb-0">
                    {{ "loyalty.coupon.time".trans() }}
                </p>
                <p class="text-dark fw-bold fs-5 mb-0">
                    {{ formatDate(draftData.LoyaltyDuration.from) }} -
                    {{ formatDate(draftData.LoyaltyDuration.to) }}
                </p>
            </div>
            <div class="w-25">
                <p class="text-soft-secondary text-uppercase fw-bold fs-6 mb-0">
                    {{ "loyalty.coupon.usage_limit".trans() }}
                </p>
                <p class="text-dark fw-bold fs-5 mb-0">
                    {{ draftData.LoyaltyUsage }}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
    draftData: {
        type: Object,
        required: true,
    },
});

function formatDate(dateTimeString) {
    if (!dateTimeString) return "-";

    let date = dateTimeString.split(", ")[0];

    return date.replaceAll("-", ".");
}
</script>
